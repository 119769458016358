export const usersMixin = {
    methods: {
        createArrFromObject(obj) {
            if (this.AUTH.isRequest) {
                return
            }
            let result = [];
            for (let [key, value] of Object.entries(obj)) {
                result.push({value: key, placeholder: value})
            }
            return result
        }
    }
}